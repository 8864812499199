var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"V5G7_MsTYht45DxN_5pM9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://47b9653ffc2d4b2d8508ca58ec0ae46e@o4504176557490176.ingest.sentry.io/4504176559194112',
  environment: process.env.NEXT_PUBLIC_host,
  tracesSampleRate: process.env.NEXT_PUBLIC_host === "production" ? 0.10 : 1,
  replaysSessionSampleRate: process.env.NEXT_PUBLIC_host === "production" ? 0.10 : 0,
  ignoreErrors: ['Non-Error promise rejection captured'],
  // integrations: [
  //   new Sentry.Feedback({
  //     colorScheme: "light",
  //     showBranding: false,
  //     showEmail: false,
  //     showName: false,
  //     formTitle: "Send Feedback or Report a Bug",
  //     buttonLabel: "Feedback",
  //     submitButtonLabel: "Send",
  //     messagePlaceholder: "Tell us about your experience. What did you like? What can we do better?"
  //   }),
  // ],
});
